import React from 'react';
import { Heading as ChakraHeading } from '@chakra-ui/react';

const getDefaultHeadingProps = (headingAsProp) => {
  const heading = headingAsProp || 'h2';
  switch (heading) {
    case 'h1':
      return {
        size: 'xl',
        my: 6,
      };
    case 'h2':
      return {
        size: 'lg',
        my: 4,
      };
    case 'h3':
      return {
        size: 'sm',
        my: 3,
      };
    case 'h4':
      return {
        size: 'sm',
        my: 2,
      };
    case 'h5':
    case 'h6':
    default:
      return {
        size: 'xs',
        my: 2,
      };
  }
};

const Heading = (props) => {
  const { as: asProp } = props;
  return (
    <ChakraHeading
      {...getDefaultHeadingProps(
        typeof asProp === 'string' ? asProp : undefined,
      )}
      color="tt.darkBlue"
      sx={{
        '& > a': {
          textDecoration: 'none',
        },
      }}
      {...props}
    />
  );
};

export default Heading;
