import { useStaticQuery, graphql } from 'gatsby';

const useLogos = () => {
  const logos = useStaticQuery(graphql`
    query useLogos {
      logoSquare_w200: file(name: { eq: "TimelessTime-Logo-Square" }) {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: NONE, layout: FIXED)
        }
      }
      logoSquare_w50: file(name: { eq: "TimelessTime-Logo-Square" }) {
        childImageSharp {
          gatsbyImageData(width: 50, placeholder: NONE, layout: FIXED)
        }
      }
      logoSideBySide_h30: file(name: { eq: "TimelessTime-Logo-SideBySide" }) {
        childImageSharp {
          gatsbyImageData(height: 30, placeholder: NONE, layout: FIXED)
        }
      }
      cmiLogo: file(name: { eq: "CMI-Logo" }) {
        childImageSharp {
          gatsbyImageData(height: 35, placeholder: NONE, layout: FIXED)
        }
      }
      cipdLogo: file(name: { eq: "CIPD-Logo" }) {
        childImageSharp {
          gatsbyImageData(height: 35, placeholder: NONE, layout: FIXED)
        }
      }
    }
  `);
  return logos;
};

export default useLogos;
