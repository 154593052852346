import React, { useMemo } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import format from 'date-fns/format';
import { VStack, HStack, Text, Divider } from '@chakra-ui/react';
import { FaTwitter, FaFacebook, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import useLogos from 'timelesstime-gatsby-utils/hooks/useLogos';
import LinkBlock from '../link-block';
import CanonicalLink from '../canonical-link';

// dont destructure these - https://www.gatsbyjs.com/docs/how-to/local-development/environment-variables/#example-of-using-an-environment-variable
const nodeEnv = process.env.NODE_ENV;
const footerLOcationsBaseUrl =
  nodeEnv === 'development'
    ? 'http://localhost:8080'
    : 'https://timelesstime.co.uk';

const footerLocationsFactory = ({ showSiteIndex }) => [
  {
    href: `${footerLOcationsBaseUrl}/conditions/`,
    text: 'Conditions',
    ignore: false,
  },
  {
    href: `${footerLOcationsBaseUrl}/privacy-policy/`,
    text: 'Privacy & Cookie Policy',
    ignore: false,
  },
  {
    href: `${footerLOcationsBaseUrl}/sitemap/`,
    text: 'Site Index',
    ignore: !showSiteIndex,
  },
];

const footerSocialIcons = [
  {
    icon: () => <FaTwitter />,
    href: 'https://twitter.com/timelesstimeltd',
    ariaLabel: 'Twitter',
  },
  {
    icon: () => <FaFacebook />,
    href: 'https://www.facebook.com/TimelessTimeLtd',
    ariaLabel: 'Facebook',
  },
  {
    icon: () => <FaLinkedinIn />,
    href: 'http://www.linkedin.com/company/timelesstime-ltd',
    ariaLabel: 'LinkedIn',
  },
  {
    icon: () => <FaYoutube />,
    href: 'https://www.youtube.com/user/TimelessTimeHR',
    ariaLabel: 'Youtube',
  },
];

export default ({ showSiteIndex = true } = { showSiteIndex: true }) => {
  const footerLocations = useMemo(
    () => footerLocationsFactory({ showSiteIndex }),
    [showSiteIndex],
  );
  const { logoSquare_w50: logoSquare, cmiLogo, cipdLogo } = useLogos();
  return (
    <VStack
      mt={12}
      mb={8}
      justify="center"
      textAlign="center"
      color="gray.600"
      fontSize="sm"
      spacing={2}
    >
      <LinkBlock
        to="/"
        display="flex"
        flexDir="row"
        justifyContent="center"
        aria-label="TimelessTime Home"
      >
        <GatsbyImage
          image={getImage(logoSquare)}
          alt="TimelessTime Logo small"
        />
      </LinkBlock>
      <HStack color="gray.400" justify="center" fontSize="2xl">
        {footerSocialIcons.map(({ href, ariaLabel, icon }) => (
          <CanonicalLink
            key={href}
            aria-label={ariaLabel}
            to={href}
            showExtenalLink={false}
          >
            {icon()}
          </CanonicalLink>
        ))}
      </HStack>
      <Text>&copy; TimelessTime {format(new Date(), 'yyyy')}</Text>
      <HStack
        justifyContent="center"
        divider={<Divider orientation="vertical" height="15px" />}
      >
        <Text>Registration: 07036445</Text>
        <Text>VAT: GB 102 3367 60</Text>
      </HStack>
      <HStack
        as="nav"
        justifyContent="center"
        divider={<Divider orientation="vertical" height="15px" />}
      >
        {footerLocations
          .filter(({ ignore }) => !ignore)
          .map(({ href, text }) => (
            <CanonicalLink key={href} to={href}>
              {text}
            </CanonicalLink>
          ))}
      </HStack>
      {(cmiLogo || cipdLogo) && (
        <HStack justifyContent="center" pt={2}>
          <LinkBlock
            to="/about/"
            aria-label="About TimelessTime - Chartered Management Institute"
          >
            <GatsbyImage
              image={getImage(cmiLogo)}
              alt="Chartered Management Institute Logo"
            />
          </LinkBlock>
          <LinkBlock to="/about/" aria-label="About TimelessTime - CIPD">
            <GatsbyImage image={getImage(cipdLogo)} alt="CIPD Logo" />
          </LinkBlock>
        </HStack>
      )}
    </VStack>
  );
};
