import React, { forwardRef } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'react-ga';
import { Box, Link } from '@chakra-ui/react';
import useSiteMetadata from 'timelesstime-gatsby-utils/hooks/useSiteMetadata';

import httpsUrl from '../../utils/https-url';

const CanonicalLink = forwardRef(
  ({ to, href, activeClassName, showExtenalLink = true, ...props }, ref) => {
    // destructure activeClassName since it's not valid for standard anchors, only use on Gatsby links
    if (typeof href !== 'undefined') {
      throw new Error('`href` should not be used, use `to` prop instead');
    }

    try {
      if (to.indexOf('/') === 0 && to.indexOf('//') === -1) {
        return (
          <Link
            ref={ref}
            as={GatsbyLink}
            to={to}
            activeClassName={activeClassName}
            {...props}
          />
        );
      }
      const url = new URL(httpsUrl(to)); // pass through httpsUrl so it's a valid URL
      if (url.origin === 'null') {
        // internal link - e.g. #someID
        return <Link ref={ref} href={to} {...props} />;
      }
      const { siteUrl } = useSiteMetadata();
      const { origin: siteOrigin, hostname: siteHostName } = new URL(siteUrl);
      if (url.hostname === siteHostName) {
        return (
          <Link
            ref={ref}
            as={GatsbyLink}
            to={to.replace(siteOrigin, '')}
            activeClassName={activeClassName}
            tabIndex={0}
            {...props}
          />
        );
      }
      const { children, ...outboundLinkProps } = props;
      return (
        // ignore the eslint rule re. noopener noreferrer - OutboundLink does this for us
        // eslint-disable-next-line react/jsx-no-target-blank
        <Link
          ref={ref}
          as={OutboundLink}
          target="_blank"
          eventLabel={to}
          to={to}
          {...outboundLinkProps}
        >
          {children}
          {showExtenalLink && (
            <Box
              as="span"
              className="external-link"
              fontSize="xs"
              pl={1}
              pr={1}
            >
              <FaExternalLinkAlt
                fontSize="xs"
                aria-label="Opens in a new window"
              />
            </Box>
          )}
        </Link>
      );
    } catch (e) {
      // incase the URL is invalid, use a basic link
      return (
        <Link
          ref={ref}
          target="_blank"
          rel="noopener noreferrer"
          href={to}
          {...props}
        />
      );
    }
  },
);

export default CanonicalLink;
