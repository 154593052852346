import React from 'react';
import { Box, useTheme } from '@chakra-ui/react';

const BreakpointIndicator = () => {
  const theme = useTheme();

  // only match 'sm' etc. instead of the number based breakpoints
  const breakpointStrings = [
    ...Object.keys(theme.breakpoints).filter(
      (breakpoint) =>
        typeof breakpoint === 'string' &&
        Number.isNaN(parseInt(breakpoint, 10)),
    ),
  ];

  const colourMap = ['blue', 'red', 'blue', 'yellow', 'purple', 'pink'];

  const defaultDisplayProps = breakpointStrings.reduce(
    (carry, breakpoint) => ({
      ...carry,
      [breakpoint]: 'none',
    }),
    {
      base: 'none',
    },
  );

  const breakpoints = breakpointStrings.map((breakpoint, index) => ({
    colour: `${colourMap[index]}.100`,
    text: breakpoint,
    displayProps: {
      ...defaultDisplayProps,
      [breakpoint]: 'block',
    },
  }));

  return (
    <>
      {breakpoints.map(({ colour, text, displayProps }) => (
        <Box
          key={text}
          bg={colour}
          display={displayProps}
          w={31}
          textAlign="center"
          fontWeight="bold"
          position="fixed"
          top={0}
          left={0}
          zIndex={theme.zIndices.modal}
          pointerEvents="none"
          opacity={0.9}
        >
          {text === 'base' ? 'B' : text}
        </Box>
      ))}
    </>
  );
};

export default BreakpointIndicator;
