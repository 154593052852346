import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

import BreakpointIndicator from '../breakpoint-indicator';
import UnsupportedBrowserWarning from '../unsupported-browser-warning';
import PageFooter from '../page-footer';

// dont destructure these - https://www.gatsbyjs.com/docs/how-to/local-development/environment-variables/#example-of-using-an-environment-variable
const nodeEnv = process.env.NODE_ENV;

const Layout = ({
  navbar,
  children,
  leadInHeader,
  footerProps,
  showSiteIndex,
}) => (
  <>
    <UnsupportedBrowserWarning />
    {navbar}
    <Box>
      <Box as="main" role="main">
        <Box as="article">
          {leadInHeader}
          {children}
        </Box>
      </Box>
    </Box>
    <Box as="footer" {...footerProps}>
      <PageFooter showSiteIndex={showSiteIndex} />
    </Box>
    {nodeEnv === 'development' && <BreakpointIndicator />}
  </>
);
Layout.defaultProps = {
  leadInHeader: null,
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  leadInHeader: PropTypes.node,
  navbar: PropTypes.node.isRequired,
};

export default Layout;
