const fileTypeRegex = /(?<filetype>(jpg|webp|png))/;

const calculateImageType = (src) => {
  if (!src) {
    return null;
  }
  const matches = src.match(fileTypeRegex);
  const filetype = matches?.groups?.filetype;
  if (filetype) {
    return `image/${filetype}`;
  }
  return null;
};

export default calculateImageType;
