import React from 'react';
import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';

const UnsupportedBrowserWarning = () => {
  const {
    isOpen: browserWarningVisible,
    onToggle: toggleBrowserWarning,
  } = useDisclosure({
    defaultIsOpen: true,
  });
  return (
    <Box
      aria-hidden="true"
      sx={{
        '@supports (display: grid)': {
          display: 'none',
          width: 0,
          height: 0,
          visibility: 'hidden',
          left: -10000,
          top: -10000,
        },
      }}
    >
      <Collapse in={browserWarningVisible}>
        <Alert status="warning" mb={2}>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Browser Not Supported</AlertTitle>
            <AlertDescription display="block">
              Sorry, your browser is not supported and the website may not
              display correctly. Please update to a modern browser to get the
              best browsing experience.
            </AlertDescription>
          </Box>
          <CloseButton
            onClick={toggleBrowserWarning}
            position="absolute"
            right="8px"
            top="8px"
          />
        </Alert>
      </Collapse>
    </Box>
  );
};

export default UnsupportedBrowserWarning;
