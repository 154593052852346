import React, { useCallback, useState } from 'react';
import { Box, Stack, useToken, Container, Flex } from '@chakra-ui/react';
import { CgClose, CgMenu } from 'react-icons/cg';
import CanonicalLink from '../canonical-link';

import Logo from './logo';

const NavBar = ({ navLinks, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen(!isOpen));
  return (
    <NavBarContainer {...props}>
      <Flex justifyContent="space-between">
        <Logo />
        <MenuToggle toggle={toggle} isOpen={isOpen} />
      </Flex>
      <MenuLinks navLinks={navLinks} isOpen={isOpen} setIsOpen={setIsOpen} />
    </NavBarContainer>
  );
};

const MenuToggle = ({ toggle, isOpen, ...props }) => {
  const gray = useToken('color', 'gray.900');
  return (
    <Box
      display={{ base: 'flex', md: 'none' }}
      alignItems="center"
      fontSize="2xl"
      color={gray}
      onClick={toggle}
      cursor="pointer"
      {...props}
    >
      {isOpen ? <CgClose /> : <CgMenu />}
    </Box>
  );
};

const MenuLinks = ({ navLinks, isOpen, setIsOpen, ...props }) => (
  <Flex
    display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }}
    flexBasis={{ base: '100%', md: 'auto' }}
    justifyContent="center"
    justifySelf="flex-end"
    ml={[0, 0, 2]}
    {...props}
  >
    <Stack
      spacing={[4, 4, 6, 8]}
      align="center"
      justify={['center', 'space-between', 'flex-end', 'flex-end']}
      direction={['column', 'column', 'row', 'row']}
      pt={[4, 4, 0, 0]}
      mb={[2, 2, 0]}
    >
      {navLinks.map(({ url, text, ...navProps }) => (
        <CanonicalLink
          fontSize="sm"
          key={url}
          to={url}
          activeClassName="active"
          display="block"
          textDecoration="none"
          _hover={{
            textDecoration: 'underline',
          }}
          sx={{
            '&.active': {
              textDecoration: 'underline',
              opacity: 0.8,
            },
          }}
          {...navProps}
          onClick={(...args) => {
            setIsOpen(false);
            if (typeof navProps?.onClick === 'function') {
              navProps?.onClick(...args);
            }
          }}
        >
          {text}
        </CanonicalLink>
      ))}
    </Stack>
  </Flex>
);

const NavBarContainer = ({ children, ...props }) => (
  <Container
    as="nav"
    display="flex"
    maxW="container.lg"
    align="center"
    justifyContent="space-between"
    flexDir={['column', 'column', 'row']}
    wrap="wrap"
    w="100%"
    pt={2}
    pb={2}
    mb={0}
    bg="transparent"
    color="gray.900"
    {...props}
  >
    {children}
  </Container>
);

export default NavBar;
