import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { getSrc, getImage } from 'gatsby-plugin-image';
import useSiteMetadata from 'timelesstime-gatsby-utils/hooks/useSiteMetadata';
import useLogos from 'timelesstime-gatsby-utils/hooks/useLogos';

import httpsUrl from '../../utils/https-url';

const SEO = ({
  lang,
  ogType,
  meta,
  keywords,
  title,
  description,
  canonicalPath,
  thumbnail,
}) => {
  const location = useLocation();
  const { siteUrl, title: siteTitle, author: siteAuthor } = useSiteMetadata();
  const { logoSquare_w200: logoSquare } = useLogos();
  const logoSrc = getSrc(getImage(logoSquare));
  const defaultThumbnail = `${siteUrl}${logoSrc}`;
  const canonicalUrl = canonicalPath
    ? `https://timelesstime.co.uk${canonicalPath.replace(/\/?(\?|#|$)/, '/$1')}`
    : null;
  const primaryImage = thumbnail
    ? httpsUrl(getSrc(getImage(thumbnail)))
    : defaultThumbnail;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords || [],
        },
        {
          name: 'image',
          content: primaryImage,
        },
        {
          property: 'og:url',
          content: location.href,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:image',
          content: primaryImage,
        },
        {
          property: 'og:type',
          content: ogType,
        },
        {
          property: 'og:locale',
          content: 'en_GB',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: siteAuthor,
        },
        {
          name: 'twitter:site',
          content: siteAuthor,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:image',
          content: primaryImage,
        },
        ...meta,
      ]}
      link={[
        {
          rel: 'canonical',
          href: canonicalUrl,
        },
      ]}
    />
  );
};

SEO.defaultProps = {
  lang: 'en',
  ogType: 'website',
  meta: [],
  keywords: [],
  thumbnail: null,
};

SEO.propTypes = {
  lang: PropTypes.string,
  ogType: PropTypes.string,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  canonicalPath: PropTypes.string.isRequired,
  thumbnail: PropTypes.object,
};

export default SEO;
