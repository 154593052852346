import React from 'react';
import { getImage, getSrc } from 'gatsby-plugin-image';
import useLogos from 'timelesstime-gatsby-utils/hooks/useLogos';
import { Img } from '@chakra-ui/react';
import LinkBlock from '../link-block';
import Preload from '../links/preload';
import calculateImageType from '../../utils/image-type';

export default function Logo({ ...props }) {
  const { logoSideBySide_h30: logoSideBySide } = useLogos();
  const logoImage = getImage(logoSideBySide);
  const logoImageSrc = getSrc(logoSideBySide);
  const logoImageType = calculateImageType(logoImageSrc);
  const { width, height } = logoImage;
  return (
    <>
      {logoImageType && (
        <Preload url={logoImageSrc} as="image" type={logoImageType} />
      )}
      <LinkBlock
        to="/"
        minWidth={width}
        maxWidth={width}
        minHeight={height}
        maxHeight={height}
        {...props}
      >
        <Img
          src={logoImageSrc}
          htmlWidth={width}
          htmlHeight={height}
          alt="TimelessTime Logo"
        />
      </LinkBlock>
    </>
  );
}
