import React, { cloneElement } from 'react';
import { LinkBox, LinkOverlay } from '@chakra-ui/react';
import CanonicalLink from '../canonical-link';

const LinkBlock = ({ to, children, showExtenalLink = false, ...props }) => (
  <LinkBox tabIndex="0" {...props}>
    {cloneElement(children, { tabIndex: '0', role: 'link' })}
    <LinkOverlay
      as={CanonicalLink}
      showExtenalLink={showExtenalLink}
      to={to}
      aria-hidden="true"
      tabIndex="-1"
    />
  </LinkBox>
);

export default LinkBlock;
