import React from 'react';
import TimelessTimeNavBar from 'timelesstime-ui/components/nav-bar';

const navLinks = [
  {
    url: '/',
    text: 'Home',
    display: {
      md: 'none',
      lg: 'block',
    },
  },
  {
    url: '/services/',
    text: 'Services',
  },
  {
    url: '/tools/',
    text: 'Tools',
  },
  {
    url: '/books/',
    text: 'Books',
  },
  {
    url: '/case-studies/',
    text: 'Case Studies',
  },
  {
    url: '/search/',
    text: 'Search',
    display: {
      md: 'none',
      lg: 'block',
    },
  },
  {
    url: '/about/',
    text: 'About',
  },
  {
    url: '/contact/',
    text: 'Contact',
  },
];

const NavBar = () => <TimelessTimeNavBar navLinks={navLinks} />;

export default NavBar;
